import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../constants/global.css"

import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { Markup } from "interweave"
import axios from "axios"
import { GET_MODULE_KEY } from "../constants/api"
import ModuleKeys from "../constants/ModuleKeys"
import styled from "styled-components"
import { device } from "../constants/breakpoints"
const Wrapper = styled.div`
max-width:1200px;
margin:auto;
      min-height: 500px;
        margin-bottom: 150px;
`
const Container = styled.div`
  border-radius: 12px;
  background: white;
  box-shadow: 5px 5px 20px #efefef;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
  margin-right: 5%;
  color: ${props => props.theme.darkblue};
  line-height: 30px;
  & > div > h3 > img {
    max-width: 100%;
  }
  & > div > h3 {
    color: ${props => props.theme.midblue};
    margin-top: 40px;
    margin-bottom: 20px;
    :nth-child(1) {
      margin-top: 10px;
    }
  }

  @media ${device.tablet} {
    margin: 0px;
    border-radius: 0px;
  }


`


const PrivacyPolicy = ({ location }) => {
  const [markup,setMarkup] = React.useState()
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "PrivacyPolicy",
    crumbSeparator: " / ",
  })

  const fetchData = async () => {
    const { data } = await axios.get(GET_MODULE_KEY(ModuleKeys.PRIVACY_POLICY))
    const modulo = data.find(item => item.key_modulo === "privacy_policy");
    setMarkup(modulo?.descrizione);
    console.log(module?.descrizione)
  }
  useEffect(() => {
    fetchData()
  } ,[])

  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Wrapper>
      <Container>
        <Markup content={markup} />
      </Container>
      </Wrapper>
    </Layout>
  )
}

export default PrivacyPolicy
